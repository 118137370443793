
class ResponseObject  {
    isError = false;
    status = {
        code: 200,
        message: ''
    };
    error = {
        'message': false,
        'info' : false,
        'code' : false,
    };
    code = 200;
    data = null;
}
export default  {
    getResponseObject : function () {
        return new ResponseObject;
    }
};

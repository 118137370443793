let _ = require('lodash/object');

export default  class asyncResponseAdapter {
    options =  {};
    constructor (options) {
        if (typeof options === 'object' && options !== null) {
            this.options = _.merge(this.options, options) ;
        }
        return this;
    }

    parse (response, options) {
        console.log('todo - request get, please override in child classes');
        return 'todo';
    }


}
